/**
 * this module contains all rules for determining "attending the office" rules
 */
import { DateTime } from "luxon";

/**
 * different types of weeks, each item represents a week with its seven days,
 * each day of a week is the identifier of the group which may attend the office
 * on this day. An empty value should be used for days without attending rules,
 * especially weekend days.
 */
const weekTypes = [
  ["A", "A", "B", "B", "A", "_", "_"],
  ["A", "A", "B", "B", "B", "_", "_"],
];

// date when this implemented corona pattern began
const bigBang = DateTime.fromISO("2020-10-12");

/**
 * get the week type of the given day
 *
 * @param day {Luxon.DateTime}
 * @return {int} index on the `weekTypes` array which represents the type of week
 *   for week containing the given day
 */
function weekType(day) {
  // `this` will refer to the component instance
  // each second week is the same

  // in the time before the Corona rules, the week has no type
  if (day < bigBang) {
    return ["", "", "", "", "", "_", "_"];
  }

  // first week: 12.10.2020 starting with "type 1"
  let weeks = day.startOf("week").diff(bigBang, "weeks").weeks;

  let weekType;
  if (weeks % 2 == 0) {
    weekType = weekTypes[0];
  } else {
    weekType = weekTypes[1];
  }

  return weekType;
}

/**
 * get the group which is allowed to attend the office on the given day
 *
 * @param day {Luxon.DateTime}
 */
function group(day) {
  let weekType_ = weekType(day);

  let group = weekType_[day.weekday - 1];
  return group;
}

export { weekType, group };
