<template>
<div>
  <CoronaTable />
  <div>
    <img src="./assets/sitzplan.png" width="1300" />
  </div>
</div>
</template>

<script>
import CoronaTable from './components/CoronaTable.vue'

export default {
  name: 'App',
  components: {
    CoronaTable
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
