<template>
	<div class="container container-md">
        <div class="my-2">
        <button class="btn btn-info" @click="prev()">&lt;</button>
        <button class="btn btn-info mx-2" :disabled="isToday" @click="today()">Today</button>
        <button class="btn btn-info" @click="next()">&gt;</button>
        </div>
	<table class="table table-striped table-bordered">
        <Week v-for="week in weeks" :key="week.startDate" :startDate="week.startDate" :first="week.first" />
	</table>
	</div>
</template>

<script>
import Week from './Week.vue'

import { DateTime } from 'luxon'

export default {
  name: 'CoronaTable',
  components: { Week },
  data() {
      return {
        startDate: DateTime.local().startOf("week"),
        currentWeekStartDate: DateTime.local().startOf("week"),
        currentWeek: DateTime.local().startOf("week").toFormat('WW')
      }
  },
  computed: {
      weeks() {
          let weeks = [];

          for (let w = 0; w < 4; w++)  // 3 weeks
          {
              let weekStartDate = this.startDate.plus({ weeks: w })
              weeks.push({
                  first: (w == 0),
                  startDate: weekStartDate
              })
          }
          return weeks;
      },
      isToday() {
          /**
           * if current week is displayed as first week, this function returns true, indicating that
           * the "today" button would have no function
           */
          //alert(`start: ${this.startDate} curre=${this.currentWeekStartDate}`)
           return (this.startDate.hasSame(this.currentWeekStartDate, 'day'));
      }
  },
  methods: {
      today() {
          this.startDate = DateTime.local().startOf("week");
      },
      prev() {
          this.startDate = this.startDate.minus({ weeks: 1 });
      },
      next() {
          this.startDate = this.startDate.plus({ weeks: 1 });
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.table td {
   text-align: center;   
}
	.table th {
   text-align: center;   
}
</style>
