<template>
		<tr :class="{ 'table-primary': currentWeek }">
			<th>KW {{week}}</th>
			<td>{{month}}</td>
            <td v-for="day in days" :key="day.date">
                <Day :day="day.date" :group="day.group" />
                </td>
            </tr>
</template>

<script>
import Day from './Day.vue'

import { DateTime } from 'luxon'
import { group } from "@/modules/rules.js";


export default {
  name: 'Week',
  components: { Day },
  props: {
    startDate: DateTime,
    first: Boolean
  },
  computed: {
      days() {
          let days = [];
          let startDate = this.startDate;
          for (let d = 0; d < 7; d++) { // show 5 days (work week)
              let day = startDate.plus({ days: d })
              days.push({
                  date: day,
                  group: group(day)
              })
          }
          return days;
      },
      week() {
          return this.startDate.toFormat('WW'); 
      },
    currentWeek() {
        // if start date of the week is in the current calendar week, mark it
        let startOfCurrentWeek = DateTime.local().startOf('week');
        return this.startDate.equals(startOfCurrentWeek);
      },
      month() {
          // if first of month is in this week, print month name
          let lastWeekDay = this.startDate.endOf('week').day;
          if (lastWeekDay < 7) {
              // new month begins in this week - but when?
              return `${this.startDate.toFormat('MMM')}/${this.startDate.plus({month: 1}).toFormat('MMM')}`;
          }
          else if (lastWeekDay == 7) {
              // new month begins at start of this week
              return `${this.startDate.toFormat('MMM')}`;
          } else if (this.first) {
              return `${this.startDate.toFormat('MMM')}`;
          }

          return null;
      }
  }
}
</script>
