<template>
<td :class="cssClass">
    <div class="small">{{weekday}}</div>
    <div><strong>{{date}}</strong></div>
    <div v-if="group && group != '_'" class="font-weight-bold boxed">Gruppe {{group}}</div>
    <div v-else>&nbsp;</div>
</td>
</template>

<script>

import { DateTime } from 'luxon'

const cssClasses = {
              B: 'bg-success',
              A: 'bg-info',
              _: 'bg-light', // weekend days
              '': 'bg-white' // rules where no rules are applied (e.g. before start of the Corona rules)
          };

export default {
  name: 'Day',
  props: {
    day: DateTime,
    group: String
  },
  data() {
    let classes = [];
    if (this.day.hasSame(DateTime.local(), "day")) {
        classes.push('today')
    }
    classes.push(cssClasses[this.group]);

    return {
        cssClass: classes,
        weekday: this.day.toFormat('EEE')
    }
  },
  computed: {
      date() {
          return this.day.toFormat('dd.MM.yyyy'); 
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.boxed {
   text-align: center;
   border: 0px solid #ccc;
   border-radius: 3px;
   background-color: rgba(255, 255, 255, 0.5);
   padding: 2px 4px;
}

	.today {
   border: 5px solid #fd7e14;
}
</style>
